import { PontCore } from '@/services/pontCore';
import { api } from '@/utils/api';
import { responseInterceptor } from '@/utils/responseInterceptor';
import { interceptorWithToken } from '@verifime/api-definition';
import { usePasswordless, useTokens } from '@verifime/cognito-passwordless-auth';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export default function AuthenticatedContainer({ children }: { children: React.ReactElement }) {
  const { tokensParsed } = usePasswordless();
  const { getTokens, getTokensParsed } = useTokens();
  const router = useRouter();

  React.useEffect(() => {
    const validateTokensParsed = (tokensParsedToValidate: typeof tokensParsed) =>
      tokensParsedToValidate && new Date() < tokensParsedToValidate.expireAt;

    if (!validateTokensParsed(tokensParsed)) {
      getTokensParsed().then((newTokensParsed) => {
        if (!validateTokensParsed(newTokensParsed)) {
          const currentPath = router.pathname;
          if (currentPath !== '/') {
            router.push({ pathname: '/', query: router.query });
          }
        }
      });
    }
  }, [getTokensParsed, router, tokensParsed]);

  PontCore.useFetch(async (url, options) => {
    const { accessToken: token } = (await getTokens()) || {};
    return await responseInterceptor(url, token, options);
  });

  useEffect(() => {
    api.use(
      interceptorWithToken({
        getToken: async () => getTokens().then(({ accessToken }) => accessToken),
      }),
    );
  }, [getTokens]);

  return <>{children} </>;
}
