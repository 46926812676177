import AuthenticatedContainer from '@/components/AuthenticatedContainer';
import createEmotionCache from '@/createEmotionCache';
import { SWRProvider } from '@/services/hooks';
import '@/services/index';
import { APPLICATION_NAME } from '@/utils/constants';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Passwordless, PasswordlessContextProvider } from '@verifime/cognito-passwordless-auth';
import {
  ConfirmProvider,
  ErrorNotification,
  FavIcons,
  GlobalStyleOverride,
  SuccessNotification,
} from '@verifime/components';
import '@verifime/design-tokens/dist/tokens.css';
import { ToggleTheme } from '@verifime/styles';
import '@verifime/styles/styles/globals.css';
import { isClient } from '@verifime/utils';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { ReactElement, ReactNode } from 'react';

if (isClient) {
  Passwordless.configure({
    cognitoIdpEndpoint: process.env.NEXT_PUBLIC_COGNITO_IDP_ENDPOINT,
    clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USERPOOL_ID,
    storage: window.localStorage,
    fido2: {
      baseUrl: process.env.NEXT_PUBLIC_FIDO2_BASE_URL,
      authenticatorSelection: {
        userVerification: 'required',
      },
      rp: {
        id: process.env.NEXT_PUBLIC_FIDO2_RELYING_PARTY_ID, // default is not set and fallback to hostname e.g. localhost
      },
    },
    totp: {
      baseUrl: process.env.NEXT_PUBLIC_TOTP_BASE_URL,
      issuer: process.env.NEXT_PUBLIC_TOTP_ISSUER ?? 'VerifiMeDev',
    },
    debug: console.debug,
  });
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } });

type TAppProps = {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
} & AppProps;

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  requiresAuthentication?: boolean;
};

const App = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: TAppProps) => {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <SWRProvider>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{APPLICATION_NAME}</title>
            <meta name="description" content="*For internal use only" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <FavIcons />
          </Head>

          <ToggleTheme>
            <GlobalStyleOverride />
            <SnackbarProvider
              Components={{
                successNotification: SuccessNotification,
                errorNotification: ErrorNotification,
              }}
            >
              <ConfirmProvider>
                <PasswordlessContextProvider enableLocalUserCache={true}>
                  {Component.requiresAuthentication ? (
                    <AuthenticatedContainer>
                      <>{getLayout(<Component {...pageProps} />)}</>
                    </AuthenticatedContainer>
                  ) : (
                    <>{getLayout(<Component {...pageProps} />)}</>
                  )}
                </PasswordlessContextProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ToggleTheme>
        </CacheProvider>
      </QueryClientProvider>
    </SWRProvider>
  );
};

export default App;
